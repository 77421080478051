<template>
    <div class="PromotionOne">
        <div class="PromotionOneHeader">
            <span class="txt">基础规则</span>
            <div class="name">
                <span>促销名称</span>
                <el-input style="width: 300px;margin-left: 10px" v-model="promotionName"></el-input>
            </div>
            <span class="txt2">设置优惠券门槛及内容</span>
        </div>
        <div class="PromotionOneTable">
            <el-table :data="tableData" border style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC" :cell-style="{height: '70px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}">
                <el-table-column label="设置优惠门槛" prop="">
                    <template slot-scope="scope">
                        <div class="discounts">
                            <el-radio v-model="radio" label="1"><span>满</span><el-input style="width: 100px;margin: 0 10px" v-model="fullPrice"></el-input><span>元</span></el-radio>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="设置优惠内容" prop="">
                    <template slot-scope="scope">
                        <div class="discounts">
                            <el-radio-group v-model="radio2" @change="selectDiscounts">
                                <el-radio :disabled="isDisabled" :label="1"><span>减</span><el-input v-model="subtractPrice" style="width: 100px;margin: 0 10px"></el-input><span>元</span></el-radio>
                                <el-radio :disabled="isDisabled2" style="margin-top: 20px" :label="2">送赠品
                                    <span @click="dialogFormVisible = true" v-if="radio2 == '2' && !isSelectComplimentary" style="color: #1E63F1;margin-left: 16px">设置赠品</span>
                                    <span @click="dialogFormVisible = true" v-if="isSelectComplimentary && radio2 == '2'" style="color: #1E63F1;font-size: 14px">赠品(1)个商品</span>
                                </el-radio>
                            </el-radio-group>
                            <div class="addStage" v-if="radio2 == 1">
                                <i class="iconfont">&#xe727;</i>
                                <span @click="newlyStage">新增阶段</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table2">
            <el-table v-if="tableData2.length" :data="tableData2" border style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC" :cell-style="{height: '70px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}">
                <el-table-column label="" prop="fullPrice">
                    <template slot-scope="scope">
                        <div class="discounts" v-if="scope.row">
                        <el-radio v-model="radio3" label="1"><span>满</span><el-input v-model="scope.row.fullPrice" style="width: 100px;margin: 0 10px"></el-input><span>元</span></el-radio>
                        </div>
                    </template>s
                </el-table-column>
                <el-table-column label="" prop="subtractPrice">
                    <template slot-scope="scope">
                        <div class="discounts" v-if="scope.row">
                        <el-radio style="margin-left: 16px" v-model="radio3" label="1"><span>减</span><el-input v-model="scope.row.subtractPrice" style="width: 100px;margin: 0 10px"></el-input><span>元</span></el-radio>
                        <div class="addStage">
                            <i class="iconfont">&#xe729;</i>
                            <span @click="deleStage(scope.$index)">删除阶段</span>
                        </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            </div>
            <div class="ParticipateIn">
                <span style="color:#FF0000;font-size: 16px">*</span>
                <span style="margin-left: 4px">商品参与方式</span>
                <el-radio-group v-model="radio4" style="margin-left: 20px">
                    <el-radio :label="1">部分参与</el-radio>
                    <el-radio :label="2">全店参与</el-radio>
                </el-radio-group>
            </div>
            <div class="ParticipateBtn">
                <el-button @click="toBack">上一步</el-button>
                <el-button style="background: #FD4446;border-color: #FD4446;color: #ffffff" @click="toSelectShop">{{radio4 == '1' ? '选择主商品' : '提交'}}</el-button>
            </div>
        </div>
        <el-dialog title="设置赠品" class="ParticipateDialog" :visible.sync="dialogFormVisible">
            <el-table :data="tableData3" style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC" :cell-style="{height: '74px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}">
                <el-table-column width="55" align="center">
                    <template slot-scope="scope">
                        <el-radio-group v-model="radio5" @change="selectComplimentaryValue">
                            <el-radio :label="scope.$index">{{scope.$index+1}}</el-radio>
                        </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="name" align="center" width="300">
                    <template slot-scope="scope">
                        <div class="shopData">
                            <img :src="scope.row.image.src" alt="">
                            <span>{{scope.row.name}} <b>在售</b></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品价格" prop="price" align="center"></el-table-column>
                <el-table-column label="库存" prop="inventory" align="center"></el-table-column>
            </el-table>
            <div class="ParticipateDialogBottom">
                <span class="span1">用户最多可选择1个赠品</span>
                <span>共{{tableData3.length}}条</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="colseParticipate">取 消</el-button>
                <el-button type="primary" @click="submitParticipate">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import _ from "underscore";
    export default {
        name: "operationPromotionStorePromotionOne",
        data(){
            return {
                fullPrice:'', //满
                subtractPrice:'', //减
                promotionName:'',
                radio:'1',
                radio2:1,
                radio3:'1',
                radio4:1,
                radio5:'',
                resData:{},
                tableData:[{}],
                tableData2:[],
                isDisabled:false,
                isDisabled2:false,
                tableData3:[],
                dialogFormVisible:false,
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                isSelectComplimentary:false,
                editData:null,
            }
        },
        created() {
            this.getShowStoreMarket();
            this.getGoClassify();
            this.getEditData();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
               'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getEditData(){
                if(this.$route.query.editId){
                    let param = {}
                    if(this.$route.query.classify == 2){
                        param = {
                            id:this.$route.query.editId,
                            type:1
                        }
                    } else if(this.$route.query.classify == 3){
                        param = {
                            id:this.$route.query.editId,
                            type:2
                        }
                    }
                    this.$http.axiosGetBy(this.$api.showStuMarket, param, res=>{
                        if(res.code === 200){
                            this.editData = res.data.data[0].stu_market_data[0];
                            this.promotionName = this.editData.name;
                            let activity = res.data.data[0].stu_market_data[0].activity_data.data;
                            let activity_info = res.data.data[0].stu_market_data[0].activity_data;
                            if (Number(activity_info.type) === 2) {
                                let result = _.find(activity_info.data, (item) => {
                                    return item.rule;
                                });
                                if (result.rule.goods_data) {
                                    this.isSelectComplimentary = true;
                                }
                            }
                            if(this.tableData3.length === activity.length){
                                this.radio4 = 2
                            }  else {
                                this.radio4 = 1
                            }
                            if(this.$route.query.classify == 2){
                                for(let i = 0; i < this.editData.activity_data.data.length; i++){
                                    if(this.editData.activity_data.data[i].rule){
                                        let list = this.editData.activity_data.data[i].rule.split('#');
                                        if(i == this.editData.activity_data.data.length-1){
                                            this.fullPrice = list[0];
                                            this.subtractPrice = list[1]
                                        } else {
                                            let obj = {
                                                fullPrice:list[0],
                                                subtractPrice:list[1],
                                                rule:''
                                            };
                                            this.tableData2.push(obj)
                                        }
                                    }
                                }
                                let list = [];
                                this.editData.activity_data.data.map(item=>{
                                    if(item.goods_data){
                                        list.push(item)
                                    }
                                })
                                if(this.tableData3.length == list.length){
                                    this.radio4 = 2
                                } else {
                                    this.radio4 = 1
                                }
                            } else if(this.$route.query.classify == 3){
                                let id = null;
                                let list = [];
                                this.editData.activity_data.data.map(item=>{
                                    if(item.rule){
                                        this.fullPrice = item.rule.sum || item.rule.num;
                                        id = item.rule.goods_data.id
                                    }
                                    if(item.goods_data){
                                        list.push(item)
                                    }
                                })
                                for(let i = 0; i < this.tableData3.length; i++){
                                    if(this.tableData3[i].id === id){
                                        this.radio5 = i
                                    }
                                }
                            if(this.tableData3.length == list.length){
                                this.radio4 = 2
                            } else {
                                this.radio4 = 1
                            }
                            }

                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            getShowStoreMarket(){
                this.$http.axiosGet(this.$api.showStoreMarket, res=>{
                    if(res.code === 200){
                        this.resData = res.data.data[0];
                        this.tableData3 = res.data.data[0].goods_list;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getGoClassify(){
                if(this.$route.query.classify == 1){
                    this.radio2 = ''
                } else if(this.$route.query.classify == 2){
                    this.radio2 = 1;
                    this.isDisabled2 = true;
                } else {
                    this.radio2 = 2;
                    this.isDisabled = true;
                }
            },
            newlyStage(){
                if(this.tableData2.length === 2){
                    this.$message.warning('最多只能添加三个阶段！')
                    return
                }
                let obj = {
                    fullPrice:'',
                    subtractPrice:'',
                    rule:''
                };
                this.tableData2.push(obj)
            },
            deleStage(index){
                // console.log(99,index);
                this.tableData2.splice(index,1)
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                // this.getTaskList()
            },
            selectComplimentaryValue(val){
                // console.log(192,val)
            },
            colseParticipate(){
                this.dialogFormVisible = false;
                this.radio5 = '';
            },
            submitParticipate(){
                this.dialogFormVisible = false;
                if(this.radio5 !== ''){
                    this.isSelectComplimentary = true
                }
            },
            toBack(){
              this.$router.go(-1)
            },
            selectDiscounts(val){
                if(val == 2){
                    if(this.tableData2.length){
                        this.$confirm('修改一阶梯的优惠内容，将会删掉第二及其他阶梯','提示',{
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            confirmButtonClass: 'btn-red',
                            type: 'warning'
                        }).then(()=>{
                            this.tableData2 = [];
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                        }).catch(()=>{
                            this.radio2 = 1
                            this.$message({
                                type: 'info',
                                message: '已取消操作'
                            });
                        })
                    }
                    }
            },
            toSelectShop(){
                if(!this.promotionName){
                    this.$message.warning('请填写促销名称！');
                    return
                }
                if(this.radio2 == 1){
                    if(!this.fullPrice || !this.subtractPrice){
                        this.$message.warning('每项为必填！');
                        return
                    }
                    this.tableData2.map(item=>{
                        if(item.rule){
                            if(!item.rule || !item.fullPrice || !item.subtractPrice){
                                this.$message.warning('每项为必填！');
                                return
                            }
                        }
                    })
                } else if(this.radio2 == 2){

                }
                if(this.radio2 == 1){
                    if(!Number(this.fullPrice) || !Number(this.subtractPrice)){
                        this.$message.warning('满减金额必须是数字！');
                        return;
                    }
                    if(Number(this.fullPrice) < Number(this.subtractPrice)){
                        this.$message.warning('满元不能小于减元!')
                        return
                    } else if(Number(this.fullPrice)<=0 || Number(this.subtractPrice)<=0){
                        this.$message.warning('金额不能小于0！');
                        return;
                    }
                    for(let i = 0; i < this.tableData2.length;i++){
                        if(!Number(this.tableData2[i].fullPrice) || !Number(this.tableData2[i].subtractPrice)){
                            this.$message.warning('满减金额必须是数字！');
                            return;
                        }
                        if(Number(this.tableData2[i].fullPrice) < Number(this.tableData2[i].subtractPrice)){
                            this.$message.warning('满元不能小于减元!')
                            return
                        } else if(Number(this.tableData2[i].fullPrice)<=0 || Number(this.tableData2[i].subtractPrice<=0)){
                            this.$message.warning('金额不能小于0！');
                            return;
                        }
                    }

                    if(this.radio4 == 1){
                        let list = [];
                        let obj = {
                            fullPrice:this.fullPrice, //满
                            subtractPrice:this.subtractPrice, //减
                            rule:''
                        }

                        let str = '';
                        this.tableData2.unshift(obj)
                        this.tableData2.map(item=>{
                            str += `满${item.fullPrice}元,减${item.subtractPrice}元,`;
                            item.rule = item.fullPrice + '#' + item.subtractPrice;
                            list.push(item.rule)
                        })
                        if(this.$route.query.editId){
                            this.$router.push({
                                path:'/student/operationpromotion/storepromotiontwo',
                                query:{
                                    rule:list,
                                    name:this.promotionName,
                                    activity_detail:str,
                                    editData:JSON.stringify(this.editData)
                                }
                            })
                        } else {
                            this.$router.push({
                                path:'/student/operationpromotion/storepromotiontwo',
                                query:{
                                    rule:list,
                                    name:this.promotionName,
                                    activity_detail:str
                                }
                            })
                        }

                    } else if(this.radio4 == 2){
                        this.tableData3.map(item=>{
                            delete item.create_time;
                            delete item.delete_time;
                            delete item.image.src;
                            item.image = item.image.url;
                        })
                        let activityData = {
                            type:1,
                            data:[]
                        }
                        this.tableData3.map(item=>{
                            let goodsData = {
                                goods_data:{
                                    id:item.id,
                                    name:item.name,
                                    image:item.image,
                                    price:item.price,
                                    inventory:item.inventory
                                }
                            };
                            activityData.data.push(goodsData)
                        })


                        let list = [];
                        let obj = {
                            fullPrice:this.fullPrice, //满
                            subtractPrice:this.subtractPrice, //减
                            rule:''
                        }
                        let str = `满${this.fullPrice}元,减${this.subtractPrice}元,`;
                        activityData.data.push({
                            rule: this.fullPrice + '#' + this.subtractPrice
                        })

                        if (this.tableData2.length > 0) {
                            this.tableData2.map(item=>{
                                str += `满${item.fullPrice}元,减${item.subtractPrice}元,`;
                                item.rule = item.fullPrice + '#' + item.subtractPrice;
                                let obj = {
                                    rule:item.rule
                                }
                                activityData.data.push(obj)
                            })
                        }

                        let activity_data = JSON.stringify(activityData)
                        let formData = new FormData();
                        formData.append('type','1');
                        formData.append('name',this.promotionName);
                        formData.append('activity_detail',str);
                        formData.append('activity_data',activity_data);
                        this.$http.axiosPost(this.$api.saveStudentMarket, formData, res=>{
                            if(res.code === 200){
                                if (this.$common.isCompetion()) {
                                    let menus = this.menus;
                                    if (Number(menus[1].children[1].status) !== 2) {
                                        this.operation_updateStatus(1,1,2);
                                    }
                                }
                                this.$message({
                                    type:'success',
                                    message:'提交成功！',
                                    duration:1000,
                                    onClose:()=>{
                                        this.$router.push({
                                            path: '/student/operationpromotion/storepromotion'
                                        })
                                    }
                                })
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                } else if(this.radio2 == 2){
                    if(this.radio4 == 1){
                        if(this.radio5===''){
                            this.$message.warning('请选择要赠送的商品！');
                            return;
                        }
                        if(!this.fullPrice){
                            this.$message.warning('必填项不能为空！');
                            return;
                        }
                        let giveShop = this.tableData3[this.radio5];
                        delete giveShop.create_time;
                        delete giveShop.delete_time;
                        // delete giveShop.image.src;
                        let ruleObj = {
                            sum:this.fullPrice,
                            goods_data:{
                                id:giveShop.id,
                                name:giveShop.name,
                                image:giveShop.image.url,
                                price:giveShop.price,
                                inventory:giveShop.inventory
                            }
                        }
                        this.$router.push({
                            path:'/student/operationpromotion/storepromotiontwo',
                            query:{
                                ruleObj:ruleObj,
                                name:this.promotionName,
                                editData:JSON.stringify(this.editData)
                            }
                        })
                    } else if(this.radio4 == 2){
                        if(this.radio5 === ''){
                            this.$message.warning('请选择要赠送的商品');
                            return;
                        }
                        if(!this.fullPrice){
                            this.$message.warning('必填项不能为空！');
                            return;
                        }
                        let giveShop = this.tableData3[this.radio5];
                        delete giveShop.create_time;
                        delete giveShop.delete_time;
                        // giveShop.image = giveShop.image.url;
                        let activityData = {
                            type:2,
                            data:[]
                        }
                        this.tableData3.map(item=>{
                            let goodsData = {
                                goods_data:{
                                    id:item.id,
                                    name:item.name,
                                    image:item.image.url,
                                    price:item.price,
                                    inventory:item.inventory
                                }
                            };
                            activityData.data.push(goodsData)
                        })
                        let ruleObj = {
                            rule:{
                                sum:this.fullPrice,
                                goods_data:{
                                    id:giveShop.id,
                                    name:giveShop.name,
                                    image:giveShop.image.url,
                                    price:giveShop.price,
                                    inventory:giveShop.inventory
                                }
                            }

                        }
                        activityData.data.push(ruleObj);
                        let activity_detail = JSON.stringify(activityData);
                        let str = `满${this.fullPrice}元赠送${giveShop.name}`;
                        let formData = new FormData();
                        formData.append('type','2');
                        formData.append('name',this.promotionName);
                        formData.append('activity_detail',str);
                        formData.append('activity_data',activity_detail);
                        this.$http.axiosPost(this.$api.saveStudentMarket, formData, res=>{
                            if(res.code === 200){
                                if (this.$common.isCompetion()) {
                                    let menus = this.menus;
                                    if (Number(menus[1].children[1].status) !== 2) {
                                        this.operation_updateStatus(1,1,2);
                                    }
                                }
                                this.$message({
                                    type:'success',
                                    message:'提交成功！',
                                    duration:1000,
                                    onClose:()=>{
                                        this.$router.push({
                                            path: '/student/operationpromotion/storepromotion'
                                        })
                                    }
                                })
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                } else {
                    this.$message.warning('请选择优惠内容！')
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-button--primary{
        background:#FD4446;
        color: #fff;
        border-color: #FD4446;
    }
        /deep/.el-button--default:hover{
            border-color: #FD4446;
            color: #FD4446;
            background: #ffffff;
        }

    .discounts{
        /deep/.el-radio-group{
            display: flex;
            flex-direction: column;
        }
    }
    /deep/.ParticipateDialog{
        /deep/.cell{
            padding: 0;
            margin: 0;
        }
        /deep/.has-gutter .is-leaf{
            padding: 0;
        }
        /deep/.el-radio__label{
            display: none;
        }
        .ParticipateDialogBottom{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    /deep/.has-gutter .is-leaf{
        padding-left:40px;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 14px;
        height: 14px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 14px;
    }
    .table2{
        /deep/.el-table__header-wrapper{
            display: none;
        }
    }
    .shopData{
        display: flex;
        align-items: center;
        /*justify-content: center;*/
        img{
            width: 48px;
            height: 48px;
        }
        span{
            color: #333333;
            font-size: 14px;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            b{
                font-weight: 500;
                display: inline-block;
                width: 30px;
                border: 1px solid #39CD7C;
                color: #39CD7C;
                font-size: 10px;
                /*padding: 3px 6px;*/
                height: 16px;
                text-align: center;
                line-height: 16px;
            }
        }
    }
    .PromotionOne{
        padding: 0 20px 50px 20px;
        .PromotionOneHeader{
            display: flex;
            flex-direction: column;
            .txt{
                font-size: 18px;
            }
            span{
                font-size: 16px;
                line-height: 1;
            }
            .name{
                margin-top: 30px;
            }
            .txt2{
                margin-top: 30px;
            }
        }
        .PromotionOneTable{
            .discounts{
                cursor: pointer;
                padding: 20px;
                position: relative;
                .addStage{
                    display: flex;
                    align-items: center;
                    color: #1E63F1;
                    position: absolute;
                    right: 30px;
                    top: 0;
                    i{
                        font-size: 16px;
                    }
                    span{
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .ParticipateIn{
            /*display: flex;*/
            /*align-items: center;*/
            margin-top: 68px;
            /deep/.el-radio-group{
            }
        }
        .ParticipateBtn{
            margin-top: 57px;
        }
    }
</style>